document.documentElement.setAttribute('data-useragent', navigator.userAgent);

window.onload = () => {

	objectFitImages();

	$("a[href^=\"#\"]").click(function(e) {
		e.preventDefault();

		const id = $(this).attr("href");
		const el = $(id);
		const offset = el.offset().top + "px";
		console.log(offset);

		$("html, body").animate(
			{
				scrollTop: offset
			},
			1000
		);
	});
};

google.maps.event.addDomListener(window, "load", () => {
	var gsb = {
		lat: 48.374488,
		lng: 10.895779
	};

	// Basic options for a simple Google Map
	// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
	var mapOptions = {
		zoom: 14,
		disableDefaultUI: true,
		center: new google.maps.LatLng(gsb.lat, gsb.lng), // New York
		styles: [
			{
				featureType: "water",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#d3d3d3"
					}
				]
			},
			{
				featureType: "transit",
				stylers: [
					{
						color: "#808080"
					},
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "road.highway",
				elementType: "geometry.stroke",
				stylers: [
					{
						visibility: "on"
					},
					{
						color: "#b3b3b3"
					}
				]
			},
			{
				featureType: "road.highway",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff"
					}
				]
			},
			{
				featureType: "road.local",
				elementType: "geometry.fill",
				stylers: [
					{
						visibility: "on"
					},
					{
						color: "#ffffff"
					},
					{
						weight: 1.8
					}
				]
			},
			{
				featureType: "road.local",
				elementType: "geometry.stroke",
				stylers: [
					{
						color: "#d7d7d7"
					}
				]
			},
			{
				featureType: "poi",
				elementType: "geometry.fill",
				stylers: [
					{
						visibility: "on"
					},
					{
						color: "#ebebeb"
					}
				]
			},
			{
				featureType: "administrative",
				elementType: "geometry",
				stylers: [
					{
						color: "#a7a7a7"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#ffffff"
					}
				]
			},
			{
				featureType: "landscape",
				elementType: "geometry.fill",
				stylers: [
					{
						visibility: "on"
					},
					{
						color: "#efefef"
					}
				]
			},
			{
				featureType: "road",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#696969"
					}
				]
			},
			{
				featureType: "administrative",
				elementType: "labels.text.fill",
				stylers: [
					{
						visibility: "on"
					},
					{
						color: "#737373"
					}
				]
			},
			{
				featureType: "poi",
				elementType: "labels.icon",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "poi",
				elementType: "labels",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{
				featureType: "road.arterial",
				elementType: "geometry.stroke",
				stylers: [
					{
						color: "#d6d6d6"
					}
				]
			},
			{
				featureType: "road",
				elementType: "labels.icon",
				stylers: [
					{
						visibility: "off"
					}
				]
			},
			{},
			{
				featureType: "poi",
				elementType: "geometry.fill",
				stylers: [
					{
						color: "#dadada"
					}
				]
			}
		]
	};

	var mapElement = document.querySelector("[data-gmaps]");
	var map = new google.maps.Map(mapElement, mapOptions);

	const image = {
		url: "./public/img/gmaps-marker.png",
		scaledSize: new google.maps.Size(25, 32),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(12, 16)
	};

	var marker = new google.maps.Marker({
		position: new google.maps.LatLng(gsb.lat, gsb.lng),
		map: map,
		icon: image,
		title: "Goldschmiede Bader"
	});
});
